export const tableColumns = [
	{
		model: 'OreservationType',
		i18n: 'type',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'OreservationName',
		i18n: 'name',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'OreservationEmail',
		i18n: 'e-mail',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'DateStart',
		i18n: 'arrive3537',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'DateEnd',
		i18n: 'depart3538',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'QuantityAdults',
		i18n: 'numberofadults3539',
		sortable: true,
		hidden: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'QuantityChildrens',
		i18n: 'numberofchildre3540',
		sortable: true,
		hidden: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'QuantityPersons',
		i18n: 'numberofpersons3551',
		sortable: true,
		hidden: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ObjectId',
		i18n: 'oid',
		sortable: true,
		filter: 'text',
		filterOptions: {
			props: {
				type: 'number',
			},
		},
	},
	{
		model: 'ObjectNameSk',
		i18n: 'object',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
		filter: 'country',
	},
	{
		model: 'OreservationDatetime',
		i18n: 'created',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'Createdby',
		i18n: 'created3074',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'OreservationLanguage',
		i18n: 'language',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'ObjectReservationReviewId',
		i18n: 'rated',
		sortable: true,
		filter: 'boolean',
	},
	{
		model: 'ObjectVisitorReservationFeedback',
		i18n: 'confirmedbygues',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
	{
		model: 'ObjectUserReservationFeedback',
		i18n: 'confirmedbyland',
		sortable: true,
		filter: 'dropdown',
		filterOptions: {
			dropdownOptions: [],
		},
	},
];
