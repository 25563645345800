var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-page", {
    directives: [
      {
        name: "show",
        rawName: "v-show",
        value: _vm.isLoaded,
        expression: "isLoaded",
      },
    ],
    staticClass: "ObjectReservationsTable",
    attrs: {
      tableName: _vm.$options.name,
      columns: _vm.columns,
      filterModel: _vm.filterModel,
      headerTitle: "reservations2926",
      tooltipMsg: "manualforreserv",
      requestFunction: _vm.requestFunction,
      defaultOrderBy: { column: "OreservationDatetime", ascending: 0 },
      modelId: "OreservationId",
      addNewRecordMsg: "",
    },
    on: {
      addNewRecord: function ($event) {
        return _vm.redirectToDetail()
      },
      editRecord: function ($event) {
        return _vm.redirectToDetail($event)
      },
      filterChanged: function ($event) {
        return _vm.handleFilter($event)
      },
      removeFilters: function ($event) {
        return _vm.removeAllFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "OreservationType",
        fn: function (list) {
          return [
            list.row.OreservationType === _vm.objectReservationType.reservation
              ? _c("b-badge", { attrs: { variant: "reservation" } }, [
                  _vm._v(_vm._s(_vm.$t("reservation2929"))),
                ])
              : _vm._e(),
            list.row.OreservationType === _vm.objectReservationType.question
              ? _c("b-badge", { attrs: { variant: "question" } }, [
                  _vm._v(_vm._s(_vm.$t("question"))),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "ObjectId",
        fn: function (list) {
          return [_vm._v("O" + _vm._s(list.row.ObjectId))]
        },
      },
      {
        key: "ObjectNameSk",
        fn: function (list) {
          return [
            _c("a", { attrs: { href: list.row.ObjectUrl, target: "_blank" } }, [
              _vm._v(_vm._s(list.row.ObjectNameSk)),
            ]),
          ]
        },
      },
      {
        key: "DateStart",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("date")(list.row.DateStart)))]
        },
      },
      {
        key: "DateEnd",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm._f("date")(list.row.DateEnd)))]
        },
      },
      {
        key: "CountryId",
        fn: function (list) {
          return [
            _c("country-flag", { attrs: { countryId: list.row.CountryId } }),
          ]
        },
      },
      {
        key: "OreservationDatetime",
        fn: function (list) {
          return [
            _vm._v(_vm._s(_vm._f("dateTime")(list.row.OreservationDatetime))),
          ]
        },
      },
      {
        key: "ObjectVisitorReservationFeedback",
        fn: function (list) {
          return [
            _c("feedback-history", {
              attrs: {
                reservationFeedback: list.row.ObjectVisitorReservationFeedback,
                visitorFeedback: true,
                reasonList: _vm.CancelVisitorReasonDic,
              },
            }),
          ]
        },
      },
      {
        key: "ObjectUserReservationFeedback",
        fn: function (list) {
          return [
            _c("feedback-history", {
              attrs: {
                reservationFeedback: list.row.ObjectUserReservationFeedback,
                visitorFeedback: false,
                reasonList: _vm.CancelOwnerReasonDic,
              },
            }),
          ]
        },
      },
      {
        key: "Createdby",
        fn: function (list) {
          return [_vm._v(_vm._s(_vm.formatCreatedBy(list.row.Createdby)))]
        },
      },
      {
        key: "ObjectReservationReviewId",
        fn: function (list) {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-row align-items-center justify-content-end",
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true },
                      },
                    ],
                    attrs: {
                      title:
                        _vm.$i18n.t("pairedrating") +
                        " " +
                        list.row.ObjectReservationReviewId,
                    },
                  },
                  [
                    list.row.ObjectReservationReviewId !== null
                      ? _c("font-awesome-icon", {
                          attrs: { icon: ["far", "comment"], size: "lg" },
                          on: {
                            click: function ($event) {
                              return _vm.routeToReview(
                                list.row.ObjectReservationReviewId
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }