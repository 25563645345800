<template>
	<div>
		<b-popover
			v-if="reservationFeedback.OreservationId"
			:target="`feedback-${reservationFeedback.OreservationId}-${this.visitorFeedback}`"
			placement="auto"
			triggers="hover"
			delay="100"
			@show="showPopover()"
			custom-class="feedback-history-popover"
		>
			<div class="review-history-wrapper" v-show="this.showTable">
				<v-client-table v-if="this.showTable" :data="this.data" :columns="columns" :options="options">
					<template v-slot:Date="list">{{ list.row.Date | dateTime }}</template>
					<template v-slot:Rating="list">
						<div class="summary-rating d-flex align-items-center pointer" @click="routeToReview(list.row.ReviewId)">
							<font-awesome-icon icon="star" size="lg" class="mr-2"></font-awesome-icon>
							<span>{{ formatRating(list.row.Rating) }}</span>
						</div>
					</template>
				</v-client-table>
			</div>
		</b-popover>
		<div :id="`feedback-${reservationFeedback.OreservationId}-${this.visitorFeedback}`">
			<boolean-display
				v-if="!reservationFeedback.OrfeedbackCancellationReasonId"
				:boolValue="reservationFeedback.OrfeedbackConfirmed"
			></boolean-display>
			<span v-if="reservationFeedback.IsConfirmedReservation" class="confirmed-reservation-text">{{ formatConfirmedReservation() }}</span>
			{{ formatCancelReason(reservationFeedback.OrfeedbackCancellationReasonId) }}
			{{ reservationFeedback.OrfeedbackNotes }}
		</div>
	</div>
</template>

<script>
import { abstractField } from 'vue-form-generator';
import * as links from '../.././router/links';
import http from '@/services/helpers/http';
import BooleanDisplay from '@/components/common/boolean-display';

export default {
	name: 'feedback-history',
	components: {
		BooleanDisplay,
	},
	props: {
		reservationFeedback: { type: Object },
		visitorFeedback: { type: Boolean },
		reasonList: { type: Object },
	},
	data() {
		return {
			controllerName: 'ObjectReservation',
			itemIdToPreview: 0,
			data: [],

			showTable: false,
			columns: this.visitorFeedback ? ['Date', 'Text', 'Rating'] : ['Date', 'Text'],
			options: {
				filterable: false,
				filterByColumn: true,
				headings: {
					Date: this.$t('dateandtime'),
					Text: this.$t('status3102'),
					Rating: this.$t('rating3103'),
				},
			},
		};
	},
	methods: {
		formatCancelReason(id) {
			if (id) {
				return this.reasonList[id].Text;
			}
		},

		formatConfirmedReservation() {
			return this.$t('wewillbeaccommo3584');
		},

		async showPopover() {
			var result = await http.get(`${this.controllerName}/GetReservationReviewHistory`, {
				reservationId: this.reservationFeedback.OreservationId,
				isVisitor: this.reservationFeedback.OrfeedbackFromVisitor,
			});

			this.data = result.data;
			this.showTable = true;
		},
		routeToReview(itemId) {
			if (itemId) {
				window.open('/object-reviews/' + itemId, '_blank');
			}
		},
		formatRating(num) {
			return Number(num).toFixed(1);
		},
	},
};
</script>

<style lang="scss">
.review-history-wrapper {
	.summary-rating {
		padding: 5px 10px;
		background-color: var(--primary);
		color: var(--white);
		width: 80px;
		font-size: 14px;

		span {
			font-size: 15px;
		}
	}
	.table-responsive {
		min-height: auto;
		.VueTables__table > th:nth-child(1) {
			width: 10%;
		}
		.VueTables__table > th:nth-child(2) {
			width: 80%;
		}
		.VueTables__table > th:nth-child(3) {
			width: 10%;
		}
		margin-bottom: 0;
	}
	.VueTables--client {
		width: 100%;
	}
	.VuePagination {
		display: none;
	}
}

$popover-width: 900px;
$popover-height: 270px;

.feedback-history-popover.b-popover {
	width: $popover-width;
	max-width: $popover-width;
	padding: 0;

	.popover-body {
		padding: 10px;
		.review-history-wrapper {
			overflow-y: auto;
			width: 100%;
			height: 100%;
		}
	}
}

.confirmed-reservation-text
{
	padding-left: 10px;
}
</style>
