<template>
	<table-page
		v-show="isLoaded"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="reservations2926"
		tooltipMsg="manualforreserv"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'OreservationDatetime', ascending: 0 }"
		modelId="OreservationId"
		addNewRecordMsg
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="ObjectReservationsTable"
	>
		<template #OreservationType="list">
			<b-badge v-if="list.row.OreservationType === objectReservationType.reservation" variant="reservation">{{
				$t('reservation2929')
			}}</b-badge>
			<b-badge v-if="list.row.OreservationType === objectReservationType.question" variant="question">{{
				$t('question')
			}}</b-badge>
		</template>

		<template #ObjectId="list">O{{ list.row.ObjectId }}</template>

		<template #ObjectNameSk="list">
			<a :href="list.row.ObjectUrl" target="_blank">{{ list.row.ObjectNameSk }}</a>
		</template>

		<template #DateStart="list">{{ list.row.DateStart | date }}</template>

		<template #DateEnd="list">{{ list.row.DateEnd | date }}</template>

		<template #CountryId="list">
			<country-flag :countryId="list.row.CountryId"></country-flag>
		</template>

		<template #OreservationDatetime="list">{{ list.row.OreservationDatetime | dateTime }}</template>

		<template #ObjectVisitorReservationFeedback="list">
			<feedback-history
				:reservationFeedback="list.row.ObjectVisitorReservationFeedback"
				:visitorFeedback="true"
				:reasonList="CancelVisitorReasonDic"
			></feedback-history>
		</template>

		<template #ObjectUserReservationFeedback="list">
			<feedback-history
				:reservationFeedback="list.row.ObjectUserReservationFeedback"
				:visitorFeedback="false"
				:reasonList="CancelOwnerReasonDic"
			></feedback-history>
		</template>

		<template #Createdby="list">{{ formatCreatedBy(list.row.Createdby) }}</template>

		<template #ObjectReservationReviewId="list">
			<div class="d-flex flex-row align-items-center justify-content-end">
				<div v-b-tooltip.hover :title="$i18n.t('pairedrating') + ' ' + list.row.ObjectReservationReviewId">
					<font-awesome-icon
						v-if="list.row.ObjectReservationReviewId !== null"
						@click="routeToReview(list.row.ObjectReservationReviewId)"
						:icon="['far', 'comment']"
						size="lg"
					></font-awesome-icon>
				</div>
			</div>
		</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import * as links from '@/router/links';
import BooleanDisplay from '@/components/common/boolean-display';
import { setDropdownOptions } from '@/components/general/utils';

import enums from '@/services/helpers/enums';
import serviceEnums from '@/services/service/enums.service';
import { tableColumns } from './object-reservation.table-data';
import FeedbackHistory from './object-reservation-feedback-history';

export default {
	name: 'ObjectReservationTable',

	components: {
		TablePage,
		BooleanDisplay,
		FeedbackHistory,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'ObjectReservationListItem',
			columns: tableColumns,

			objectReservationType: enums.objectReservationType,
			CancelVisitorReasonDic: serviceEnums.getEnumDict('reservationFeedbackCancelVisitorReason', true),
			CancelOwnerReasonDic: serviceEnums.getEnumDict('reservationFeedbackCancelOwnerReason', true),
			oReservationCreatedBy: serviceEnums.getEnumDict('reservationCreatedBy'),
		};
	},

	async created() {
		this.columns = this.prepareColumns();
	},

	methods: {
		redirectToDetail(id = 0) {
			this.$router.push({
				name: links.objectReservationDetailName,
				params: { objectReservationId: id },
			});
		},

		formatCreatedBy(createdBy) {
			return this.oReservationCreatedBy[createdBy].Text;
		},

		routeToReview(OreviewId) {
			this.$router.push({
				name: links.objectReviewsDetailName,
				params: { OreviewId },
			});
		},

		 prepareColumns() {
			let columns = tableColumns;

			columns = prepareOreservationType(columns);
			columns = prepareObjectVisitorReservationFeedback(columns);
			columns = prepareObjectUserReservationFeedback(columns);
			columns = prepareCreatedby(columns);
			columns = prepareOreservationLanguage.bind(this)(columns);

			return columns;

			function prepareOreservationLanguage(columns) {
				const values = this.$store.getters.countryLanguageCodeForDropdown;
				return setDropdownOptions(columns, 'OreservationLanguage', values);
			}

			function prepareOreservationType(columns) {
				const values = serviceEnums.getEnumForDropdown('objectReservationType', true);

				return setDropdownOptions(columns, 'OreservationType', values);
			}

			function prepareObjectVisitorReservationFeedback(columns) {
				const values = serviceEnums.getEnumForDropdown('reservationFeedbackCancelVisitorReason', true);

				return setDropdownOptions(columns, 'ObjectVisitorReservationFeedback', values);
			}

			function prepareObjectUserReservationFeedback(columns) {
				const values = serviceEnums.getEnumForDropdown('reservationFeedbackCancelOwnerReason', true);

				return setDropdownOptions(columns, 'ObjectUserReservationFeedback', values);
			}

			function prepareCreatedby(columns) {
				const values = serviceEnums.getEnumForDropdown('reservationCreatedBy', true);

				return setDropdownOptions(columns, 'Createdby', values);
			}

			// function setDropdownOptions(columns, model, values) {
			// 	const colIndex = columns.findIndex((c) => c.model === model);

			// 	columns[colIndex].filterOptions.dropdownOptions = values;

			// 	return columns;
			// }
		},
	},
};
</script>

<style lang="scss">
.ObjectReservationsTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.badge-reservation {
		background: #f4a237;
		color: #fff;
	}
	.badge-question {
		background: #82d142;
		color: #fff;
	}
}
</style>
